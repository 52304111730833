import {
  Avatar,
  Button,
  Center,
  Container,
  createStyles,
  Divider,
  Grid,
  MantineProvider,
  Skeleton,
  Stack,
  Text,
  Title
} from '@mantine/core'
import Link from 'next/link'

import { Site as SiteType } from '@/types'

interface Props {
  siteData?: SiteType
}

const useStyles = createStyles(theme => ({
  avatar: { borderRadius: '50%' },
  statusCode: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: theme.radius.sm,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 900,
    fontSize: 96,
    color: theme.colors.red[5],
    backgroundColor: theme.colors.red[0],
    [theme.fn.smallerThan('sm')]: { fontSize: 72 }
  },
  title: {
    flexGrow: 1,
    fontWeight: 900,
    fontSize: 48,
    [theme.fn.smallerThan('sm')]: { fontSize: theme.fontSizes.xl, textAlign: 'center' }
  },
  text: {
    fontSize: theme.fontSizes.lg,
    [theme.fn.smallerThan('sm')]: { fontSize: theme.fontSizes.md, textAlign: 'center' }
  }
}))

export default function Error404View({ siteData }: Props) {
  // Hooks
  const { classes } = useStyles()

  // Custom theme
  const customColor = siteData?.theme?.configuration?.customColor
  const customTheme: object | undefined =
    Array.isArray(customColor) && customColor.length === 10
      ? { colors: { custom: customColor }, primaryColor: 'custom' }
      : undefined

  return (
    <MantineProvider theme={customTheme} inherit>
      <Center sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
        <Container size="md" p="xl">
          <Divider
            my="xl"
            variant="dotted"
            size="sm"
            labelPosition="center"
            label={
              <Avatar
                src={siteData?.logo}
                alt={siteData?.seller?.displayName}
                size={60}
                className={classes.avatar}>
                <Skeleton height={60} circle />
              </Avatar>
            }
          />
          <Grid gutter="lg" justify="center">
            <Grid.Col xs={12} sm={8} order={2} orderSm={1}>
              <Stack>
                <Title className={classes.title}>Algo deu errado...</Title>
                <Text color="dimmed" className={classes.text}>
                  Aparentemente esta página não existe ou foi removida. Entre em contato com nosso
                  suporte para que possamos te encaminhar para a página correta.
                </Text>
                <Button component={Link} href="/" variant="outline" size="lg">
                  Voltar para home
                </Button>
              </Stack>
            </Grid.Col>
            <Grid.Col xs={12} sm={4} order={1} orderSm={2}>
              <Title align="center" className={classes.statusCode}>
                404
              </Title>
            </Grid.Col>
          </Grid>
          <Divider variant="dotted" size="sm" my="xl" />
        </Container>
      </Center>
    </MantineProvider>
  )
}
