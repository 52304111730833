import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'

import AssembleLayout from '@/layouts/AssembleLayout'
import { useFetch } from '@/lib/hooks'
import { urlHostResolver } from '@/lib/utils'
import { noIndex } from '@/lib/utils/next-seo.config'
import Error404View from '@/views/errors/Error404View'

export default function Error404Page() {
  // Hooks
  const router = useRouter()

  // Constants
  const hostname = typeof window !== 'undefined' ? window.location?.host : ''
  const subdomain = hostname
    .replace(`.${process.env.NEXT_PUBLIC_ROOT_URL}`, '')
    .replace(`.${process.env.NEXT_PUBLIC_VERCEL_URL}`, '')
    .replace(':3000', '')

  // Fetch
  const { data: siteData, error } = useFetch([subdomain ? `/${subdomain}/` : null])

  // SEO
  const urlSEO = siteData?.domain || urlHostResolver(subdomain, router?.asPath)
  const commonSEOProps = {
    title: `Erro 404${siteData ? ` | ${siteData.title}` : ''}`,
    description: 'O endereço que você tentou acessar não existe.'
  }

  return (
    <AssembleLayout
      View={Error404View}
      viewProps={error ? { siteData: { logo: '/images/logo/symbol-color.png' } } : { siteData }}>
      <NextSeo
        canonical={urlSEO}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: siteData?.favicon,
            type: 'img/png',
            sizes: '32x32'
          }
        ]}
        {...commonSEOProps}
        openGraph={{ ...commonSEOProps, url: urlSEO }}
        {...noIndex}
      />
    </AssembleLayout>
  )
}
